<template>
    <div class="main">
        <breadcrumb :breadcrumb="breadcrumbItems" />
        <div class="inquiry-detail">
            <div class="title">
                <h3>{{ $t("talk_room.detail") }}</h3>
            </div>
            <div class="body">
                <div class="card">
                    <div class="card-header">
                        <div class="info" v-if="detail.lesson.level">
                            {{ $t(`lesson.${detail.lesson.level}`) }}
                        </div>
                        <h3 class="card-title">{{ $t('talk_room.lesson') }}: <router-link class="black"
                                :to="`/lesson-detail/${detail.lesson.id}`">{{ detail.lesson.title }}</router-link></h3>
                    </div>
                    <div class="card-body" id="table">
                        <div class="tr">
                            <div class="td w-100 text-center">
                                <div class="cimg">
                                    <img :src="detail.groupProfile.profile_image" :alt="detail.groupProfile.name" />
                                </div>
                                <h5 class="card-title text-center">
                                    <router-link class="black" v-if="detail.groupProfile.role == 'teacher'"
                                        :to="`/teacher/${detail.groupProfile.member_id}/profile`">
                                        {{ detail.groupProfile.name }}
                                    </router-link>
                                    <router-link class="black" v-else
                                        :to="`/student/${detail.groupProfile.member_id}/profile`">
                                        {{ detail.groupProfile.name }}
                                    </router-link>
                                </h5>
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td w-100">
                                <div class="card-text descr">
                                    <div class="text-title">
                                        {{ $t('talk_room.age_or_grade') }}
                                    </div>
                                    <div class="text-descr">
                                        {{ detail.age_or_grade }}
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.content') }}
                                    </div>
                                    <div class="text-descr">
                                        {{ detail.content }}
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.schedule_date') }}
                                    </div>
                                    <div class="text-descr">
                                        <template v-if="detail.lesson.schedule && detail.lesson.schedule.start_datetime">
                                            {{ formatDateTimeJa(detail.lesson.schedule.start_datetime) }} - {{
                                                formatDateTimeJa(detail.lesson.schedule.end_datetime) }}
                                        </template>
                                        <template v-else>
                                            {{ $t('lesson.require_booked_date') }}
                                        </template>
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.teacher') }}
                                    </div>
                                    <div class="text-descr">
                                        {{ detail.teacher.name }}
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.student') }}
                                    </div>
                                    <div class="text-descr">
                                        {{ detail.replier.name }}
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.price') }}
                                    </div>
                                    <div class="text-descr">
                                        {{ detail.price_detail && formatCurrency(detail.price_detail.currency,
                                            detail.price_detail.price) }} {{ $t("lesson.included_tax") }}
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.option') }}
                                    </div>
                                    <div class="text-descr" v-if="detail.price_detail">
                                        <div v-for="(item, key) in detail.price_detail.items" :key="key">
                                            <label>{{ item.name }}</label>
                                            (<label>{{ formatCurrency(detail.price_detail.currency, item.price) }}</label>) {{ $t("lesson.included_tax") }}
                                        </div>
                                    </div>
                                    <div class="text-title">
                                        {{ $t('talk_room.purchased_at') }}
                                    </div>
                                    <div class="text-descr">
                                        {{ formatDateTimeJa(detail.talk_room_date) }}
                                    </div>
                                </div>
                                <template v-if="detail.allow_booked_date">
                                    <a @click="setSchedule"
                                        :class="`float-right text-underline ${(currentStep > 1) ? 'text-black' : ''}`"
                                        :disabled="currentStep > 1" role="button">{{ $t('talk_room.set_date_time') }}</a>
                                    <ScheduleLesson :visible="isSetSchedule" :talkRoomId="detail.talk_room_id"
                                        @cancel="cancelModal" @reload="reload" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4">
                    <div>
                        <div class="progress-container">
                            <div class="progress">
                                <div v-for="(step, index) in steps" :key="index" class="progress-bar" role="progressbar"
                                    :style="{ width: stepWidth + '%' }" :class="{ 'bg-success': index <= currentStep }">
                                    <span class="sr-only"></span>
                                </div>
                            </div>

                            <div class="d-flex justify-content-between mt-3 steps">
                                <div v-for="(step, index) in steps" :key="index">
                                    <div class="text-center mb-3">
                                        <span :class="{ 'font-weight-bold': index <= currentStep }"></span>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div class="step-indicator" :class="{ 'active': index <= currentStep }"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between" style="transform: translateY(-20px);">
                                <div class="font-weight-bold">{{ $t('talk_room.steps.consultation') }}</div>
                                <div class="font-weight-bold">{{ $t('talk_room.steps.scheduled') }}</div>
                                <div class="font-weight-bold">{{ $t('talk_room.steps.lesson_completed') }}</div>
                                <a role="button" @click="rateLesson" :disabled="currentStep != 2"
                                    class="font-weight-bold review_written">{{ $t('talk_room.steps.review_written') }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="list">
                    <div class="summary">
                        <div class="total">
                            <label>コメント</label>
                            <span>({{ reply.total }})</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="load-more" v-if="reply.hide">
                                        <b @click="handleMoreReplies" :disabled="reply.loading">
                                            {{ `${reply.hide} ${$t("talk_room.comment")} ${reply.loading ? '...' : ''}` }}
                                        </b>
                                    </div>
                                    <ListReply :replies="replies" />
                                </div>
                                <div class="col-12">
                                    <FormReply 
                                        :talkRoomId="detail.talk_room_id" 
                                        :me="detail.me"
                                        :roomStatus="detail.status"
                                        @change="handleMoreReplies" />
                                </div>
                                <RatePopup :data="rateData" :isVisible="isRatingLesson" @cancel="cancelModal"
                                    @reload="reload" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MetaTags title="トークールームリスト｜ResMom相談online"
            description="リセマム相談オンラインのトークルームリストです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import FormReply from './components/form-reply.vue';
import ListReply from './components/list-reply.vue';
import ScheduleLesson from './components/schedule-lesson.vue';
import RatePopup from '../../../../sp/mypage/lesson-list/components/components/rate-popup.vue';

export default {
    name: "TalkRoomDetail",
    components: {
        FormReply,
        ListReply,
        ScheduleLesson,
        RatePopup
    },
    data() {
        return {
            id: null,
            replies: [],
            reply: {
                page: 1,
                limit: 5,
                hide: 0,
                loading: false,
                total: 0
            },
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page"
                },
                {
                    title: this.$t("menu.breadcrumb.talk_room_list"),
                    link: "/my-page/talk-room"
                },
                {
                    title: this.$t("talk_room.detail"),
                    link: "#"
                }
            ],
            steps: ['0', '1', '2', '3'],
            isSetSchedule: false,
            rateData: null,
            isRatingLesson: false
        };
    },
    async created() {
        this.id = this.$route.params.id;
        await this.getTalkRoom({
            id: this.id,
        });
        this.listReplies(this.id);
        this.listenChannelBroadcastEvent();
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.id = this.$route.params.id;
                this.getTalkRoom({
                    id: this.id
                });
                this.handleMoreReplies(true);
            },
        },
    },
    computed: {
        ...mapGetters({
            detail: "talkRoom/detail",
        }),
        currentStep() {
            return this.detail.progress_step ?? 0;
        },
        stepWidth() {
            const widths = [0, 35, 28, 100];
            const baseWidth = widths[this.currentStep] || 0;
            return ((this.currentStep + 1) / this.steps.length) * baseWidth;
        },
    },
    methods: {
        ...mapActions({
            getTalkRoom: "talkRoom/getTalkRoom",
            getTalkRoomReply: "talkRoom/getTalkRoomReply",
            scheduleLesson: "lesson/scheduleLesson",
        }),
        setSchedule() {
            this.isSetSchedule = true;
        },
        rateLesson() {
            this.rateData = {
                teacher_id: this.detail.teacher.id,
                member_id: this.getMemberId(),
                lesson_id: this.detail.lesson.id,
                lesson_schedule_id: this.detail.lesson_schedule_id,
                lesson_level: this.detail.lesson.level,
                lesson_title: this.detail.lesson.title
            }
            this.isRatingLesson = true;
        },
        cancelModal() {
            this.isSetSchedule = false;
            this.isRatingLesson = false;
        },
        async reload() {
            await this.getTalkRoom({
                id: this.id
            });
            this.isRatingLesson = false;
            this.isSetSchedule = false;
        },
        async listReplies(id) {
            let { page, limit } = this.reply;
            await this.getTalkRoomReply({
                id: id,
                params: {
                    limit,
                    page
                },
                cb: (response) => {

                    let { data, meta } = response;
                    if (meta && meta.code == 200) {
                        let { pagination } = meta;
                        let _arr = data.reverse();
                        if (pagination.current == 1) {
                            this.replies = _arr;
                        } else {
                            this.replies = _arr.concat(this.replies);
                        }
                        let hide = pagination.total - this.replies.length;
                        if (hide < 0) {
                            hide = 0;
                        }
                        this.reply = {
                            ...this.reply,
                            hide,
                            loading: false,
                            total: pagination.total
                        }
                    }
                }
            });
        },
        async handleMoreReplies(newComment = false) {
            if (this.reply.loading) return;
            let { limit, page } = this.reply;
            if (newComment == true) {
                page = 1;
                if (limit !== 5) {
                    limit = 50;
                }
            } else {
                if (limit == 50) {
                    page = page + 1;
                }
                limit = 50;
            }
            this.reply = {
                ...this.reply,
                limit,
                page,
                loading: true
            }
            await this.listReplies(this.id);
        },
        async loadReplyAll() {
            if (this.reply.loading) return;
            this.reply = {
                ...this.reply,
                limit: this.reply.total,
                page: 1,
                loading: true
            }
            await this.listReplies(this.id);
        },
        listenChannelBroadcastEvent() {
            let memberId = this.getMemberId();
            this.echo("talk_room." + this.id + ".member." + memberId, ".created", (e) => {
                this.handleMoreReplies(true);
            });
        },
        _formatTime(start, end) {
            return (
                this.dateInstance(start).format("HH:mm") +
                " - " +
                this.dateInstance(end).format("HH:mm")
            );
        },
    },
}
</script>
<style>
.text-black {
    color: #000000;
}

.modal-body:not(:has(>.pick-panel)) {
    overflow: inherit !important;
}

.text-underline {
    text-decoration: underline;
}

.progress-bar {
    padding: 0;
    background-color: unset
}

.progress-bar span {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    text-align: center;
}

.steps {
    position: relative;
    bottom: 28px;
    left: 4px;
}

.progress-container {
    position: relative;
}

.attendance {
    font-size: 20px;
    position: relative;
    top: 15px;
    width: 22%;
}

.step-indicator {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #b5b8bb;
}

.step-indicator.active {
    background-color: #d9952f;
    border-color: #d9952f;
    color: #fff;
}

.progress {
    top: 38px;
    display: flex;
    height: 1rem;
    width: 96%;
    left: 7px;
    position: relative;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.vue-star-rating[data-v-fde73a0c] {
    display: block;
    padding-top: 10px;
}

.vue-star-rating-rating-text[data-v-fde73a0c] {
    display: none;
}

.bg-success {
    background-color: #d9952f !important;
}

.bg-secondary {
    background-color: #b5b8bb !important;
}

.review_written[disabled] {
    color: #212529;
}
</style>