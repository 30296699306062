<template>
  <div class="popup-modal" role="dialog" v-if="isVisible">
    <div class="wrapper lesson-completed">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div></div>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="_cancel"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <FormView @submit="updateCommentLesson">
            <div class="modal-body">
              <div class="card label-hide">
                <div class="card-header">
                  <div class="info">{{ $t(`lesson.${data.lesson_level}`) }}</div>
                  <h3 class="title"> {{ data.lesson_title }}</h3>
                </div>
                <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                  {{msg}}
                </div>
                <TextArea 
                    label="内容を入力してください"
                    placeholder="レビューをご記入ください"
                    name="comment"
                    :rows="7"
                    v-model="items.content"
                />
                <div class="rate">
                  <star-rating 
                    v-model="items.rate"
                    :star-size="40"
                    :read-only="false"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center">
              <button type="submit" class="btn btn-primary" :disabled="isSaving">
                {{isSaving ? $t("mypage.form.posting_button") : $t("mypage.form.post_button")}}
              </button>
            </div>
          </FormView>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'
import { mapActions } from "vuex";

export default {
  props: {
    data: Object,
    isVisible: {},
  },
  name: "Popup",
  data() {
    return {
      isSaving: false,
      msgType: "danger",
      msg: "",
      items: {
        rate: 0,
        content: "",
      },
      filters: {
        page: 1,
        tab: "finished",
      },
      request: {
        type: "finished",
        limit: 10,
        page: 1,
      },
    };
  },
  components: {
    StarRating
  },
  methods: {
    ...mapActions({
          actionUpdateCommentLesson: "mypage/updateCommentLesson",
          getLessons: "mypage/fetchMypageLessonList"
      }),
    _cancel() {
      this.items.rate = 0;
      this.items.content = "";

      this.$emit("cancel");
    },
    async updateCommentLesson() {
      if(this.isSaving)return;
      this.isSaving = true;
      if(! this.items.rate){
        this.msg = "評価が必要です";
        this.isSaving = false;
        return;
      }
      this.msg = "";

      let params = {
          ...this.items,
          teacher_id: this.data.teacher_id,
          student_id: this.data.member_id,
          lesson_id: this.data.lesson_id,
          lesson_schedule_id: this.data.lesson_schedule_id,
      }
      let response = await this.actionUpdateCommentLesson({
          params: params
      });
      this.$emit('reload');
      if (response.data) {
        this.filters = this.$router.getQuery(this.filters);
        this.request = {
              ...this.request,
              page: this.filters.page,
          };
        await this.getLessons(this.request);
      }
      this.isSaving = false;
      this._cancel();
    }
  },
};
</script>