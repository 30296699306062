<template>
  <Popup :isVisible="visible" title="" @cancel="actionCancel" @confirm="actionConfirm">
    <div class="col-12">
      <div class="alert alert-danger text-pre-line" v-if="msg">
        {{ msg }}
      </div>
    </div>
    <div class="col-12 mt-3 schedule-picker text-left">
      <div class="row">
        <div class="col-12">
          <label>開催日時</label>
        </div>
        <div class="col-12 d-flex">
          <label>日時指定</label>
          <rangeDatePicker v-model="rangeDate" :always-display="false" :format="formatDateInstance">
          </rangeDatePicker>
        </div>
        <div class="col-2 time-schedule">
        </div>
        <div class="col-5 time-schedule">
          <VueTimepicker v-model="time.start_hh" format="HH" manual-input></VueTimepicker>
          <span>{{ $t("lesson.form.hh") }}</span>
        </div>
        <div class="col-5 time-schedule">
          <VueTimepicker v-model="time.start_mm" format="mm" manual-input></VueTimepicker>
          <span>{{ $t("lesson.form.mm") }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>終了日時</label>
        </div>
        <div class="col-12 d-flex">
          <label>日時指定</label>
          <rangeDatePicker v-model="rangeDate" :always-display="false" :format="formatDateInstance" :indexRange="1">
          </rangeDatePicker>
        </div>
        <div class="col-2 time-schedule">
        </div>
        <div class="col-5 time-schedule">
          <VueTimepicker v-model="time.end_hh" format="HH" manual-input></VueTimepicker>
          <span>{{ $t("lesson.form.hh") }}</span>
        </div>
        <div class="col-5 time-schedule">
          <VueTimepicker v-model="time.end_mm" format="mm" manual-input></VueTimepicker>
          <span>{{ $t("lesson.form.mm") }}</span>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import { mapActions } from "vuex";
export default {
  name: "ScheduleLesson",
  components: {
    VueTimepicker,
  },
  props: {
    talkRoomId: { type: Number, required: true },
    visible: { type: Boolean },
  },
  data() {
    return {
      time: {
        start_hh: {
          HH: "00",
        },
        start_mm: {
          mm: "00",
        },
        end_hh: {
          HH: "00",
        },
        end_mm: {
          mm: "00",
        },
      },
      rangeDate: [],
      msg: ""
    };
  },
  methods: {
    ...mapActions({
      scheduleLesson: "talkRoom/scheduleLesson",
    }),
    async actionConfirm() {

      let start = this.formatDateInstance(this.rangeDate[0], "YYYY-MM-DD");
      let end = this.formatDateInstance(this.rangeDate[1], "YYYY-MM-DD");
      start = `${start} ${this.time.start_hh.HH}:${this.time.start_mm.mm}`;
      end = `${end} ${this.time.end_hh.HH}:${this.time.end_mm.mm}`;

      await this.scheduleLesson({
        data: {
          start,
          end
        },
        talkRoomId: this.talkRoomId,
        cb: (response) => {
          let { data, meta } = response;
          if(meta && meta.code === 200){
            this.isVisible = false;
            this.$emit("cancel");
            this.$emit("reload");
            this.msg = "";
          }else {
            if(data?.errors){
              let _error = Object.values(data.errors)[0];
              if(typeof _error  == "object")_error = _error[0]
              this.msg = this.$t(_error);
            }else {
              this.msg = this.$t("validation.update_failed");
            }
          }
        },
      });
   
    },
    actionCancel() {
      this.msg = null;
      this.$emit("cancel");
    },
  }
};
</script>
<style>
.wrapper {
  overflow-y: scroll;
}

.popup-modal {
  z-index: 99999;
  position: fixed;
}

.selected-date {
  width: 100%;
  display: block;
  max-width: 100%;
}

.schedule-picker .container {
  width: 80%;
}

.schedule-picker .time-schedule span {
  margin-left: 10px;
}

.vue__time-picker {
  width: auto;
}

.vue__time-picker input.display-time {
  width: 80px;
}
</style>
